import { Component, input, output } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';

@Component({
    selector: 'app-profile',
    standalone: true,
    imports: [],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss',
})
export class ProfileComponent {
    selectedTenant = input<string | null>();
    selectCompany = output<void>();
    userProfile = input<KeycloakProfile | null>();

    onSelectCompany(): void {
        this.selectCompany.emit();
    }
}
