import { CommonModule } from '@angular/common';
import { Component, inject, model, signal } from '@angular/core';
import { TimeConversionService } from '@app/services/time-conversion/time-conversion.service';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { gitVersion } from 'environments/git-version';

@Component({
    selector: 'app-version',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    templateUrl: './version.component.html',
    styleUrl: './version.component.scss',
})
export class VersionComponent {
    timeConversionService: TimeConversionService = inject(
        TimeConversionService,
    );

    version = signal<string>(gitVersion.version);
    yearRange = signal<string>('2024');
    customClass = model<string>('');

    labels = {
        title: signal<string>('app-version.title'),
    };

    constructor() {
        this.yearRange.set(this.timeConversionService.calculateYearRange(2024));
    }
}
