import { Action, createReducer, on } from '@ngrx/store';
import { clearLoginState, setUserInformation } from '../actions';
import { cloneDeep } from 'lodash-es';
import { UserStateModel } from '@app/models/store/user';

const initialState: UserStateModel = new UserStateModel();

const userReducer = createReducer(
    initialState,
    on(setUserInformation, (state, action): UserStateModel => {
        const _u = cloneDeep(state);

        _u.id = action.payload.id;
        _u.username = action.payload.username;
        _u.email = action.payload.email;
        _u.firstName = action.payload.firstName;
        _u.lastName = action.payload.lastName;
        _u.enabled = action.payload.enabled;
        _u.emailVerified = action.payload.emailVerified;
        _u.totp = action.payload.totp;
        _u.createdTimestamp = action.payload.createdTimestamp;
        _u.realm_access = action.payload.realm_access || { roles: [] };

        return _u;
    }),
    on(clearLoginState, (): UserStateModel => {
        return initialState;
    }),
);

export default function reducer(
    state: UserStateModel,
    action: Action,
): UserStateModel {
    return userReducer(state, action);
}
