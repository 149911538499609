import { Injectable } from '@angular/core';
import { SignalsSimpleStoreService } from '../signals-simple-store/signals-simple-store.service';

interface SelectCompanyState {
    company: string;
}

@Injectable({
    providedIn: 'root',
})
export class SelectCompanyStateService extends SignalsSimpleStoreService<SelectCompanyState> {
    constructor() {
        super();

        this.setState({
            company: '',
        });
    }
}
