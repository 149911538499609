<div class="profile">
    <div class="profile-image">
        <span>
            {{
                (
                    userProfile()?.firstName?.charAt(0) +
                    '' +
                    userProfile()?.lastName?.charAt(0)
                ).toUpperCase()
            }}
        </span>
    </div>
    <div class="profile-info" id="profile-info">
        <div class="name">
            <span id="profile-firstname">{{ userProfile()?.firstName }}</span>
            <span id="profile-lastName">{{ userProfile()?.lastName }}</span>
        </div>

        <div class="info">
            <span id="profile-email" class="profile-email">
                {{ userProfile()?.email }}
            </span>

            <a
                id="select-tenant"
                class="select-tenant"
                (click)="onSelectCompany()"
                (keydown.enter)="onSelectCompany()"
                tabindex="-1"
            >
                ({{ selectedTenant() }})</a
            >
        </div>
    </div>
</div>
