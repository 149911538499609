// [{"id":"5d5580c47d85ee4dbf0330e3","username":"user@user.com","password":"{bcrypt}$2a$10$cebD3jlod3H3P6DIqSjyeOzS33NSWXZY0vBqHawb326uzIvKqqmPq","iataCode":"XS","accountNonExpired":true,"accountNonLocked":true,"enabled":true,"credentialsNonExpired":true,"roles":["ROLE_USER"],"authorities":[{"authority":"ROLE_USER"}]},]

export class User {
    email!: string;
    token!: string;
    roles!: string[];
    allowedAirports!: string[];
    uniqueId!: string;
    iataCode!: string;
}

export class KeycloakUserInfo {
    sub = '';
    companies: string[] = [];
    email_verified = false;
    realm_access: { roles: string[] } = { roles: [] };
    name = '';
    groups: string[] = [];
    preferred_username = '';
    given_name = '';
    family_name = '';
    email = '';

    constructor(init?: Partial<KeycloakUserInfo>) {
        Object.assign(this, init);
    }
}

// {
// "id":"5d5580c47d85ee4dbf0330e4",
// "username":"admin@user.com",
// "password":"{bcrypt}$2a$10$ajdoHjcif8iyapcT9z0lleTomuVnNUe6C2O.xmzhBpz6Nr84o9y1W",
// "iataCode":"XS",
// "accountNonExpired":true,
// "accountNonLocked":true,
// "enabled":true,
// "credentialsNonExpired":true,
// "roles":["ROLE_USER","ROLE_ADMIN"],
// "authorities":[{"authority":"ROLE_USER"},{"authority":"ROLE_ADMIN"}]}
/*
export class UserFromDB {
  id!: string;
  username!: string;
  password!: string;
  personName!: string;
  mobilePhone!: string;
  iataCode: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  enabled: boolean;
  credentialsNonExpired: boolean;
  roles: string[];
  allowedAirports: string[];
  lastLogin: Date;

}

// {
//   "id": "5d5ea481b2c0c85857c211e3",
//   "userId": "5d5ea451b2c0c85857c211df",
//   "token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJLZXZpbi5PU3VsbGl2YW5Ac2l0YS5hZXJvIiwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9BRE1JTiIsIlJPTEVfU0lUQV9BRE1JTiJdLCJpYXQiOjE1NjY0ODM1ODUsImV4cCI6MTU2NjkxNTU4NX0.106hPwplx_T9lYSSN_eQvAs09zzoPdqNM2T6Jg2k-wI",
//   "ip": "0:0:0:0:0:0:0:1",
//   "location": "IPLocationResponse(id=5d5e6bb3b2c0c85275a0392a, lastUpdate=2019-08-22T10:17:23.635Z, ip=0:0:0:0:0:0:0:1, type=ipv6, continent_code=null, country_code=null, region_code=null, city=null, latitude=null, longitude=null, zip=null, country_flag=null)",
//   "userAgent": "Chrome 76.0 - Mac OS X 10.14",
//   "created": "2019-08-22T14:19:45.138Z",
//   "lastUsed": "2019-08-22T14:19:45.138Z"
// }

export class JWTToken {

  id!: string;
  userId: string;
  token: string;
  ip: string;
  userAgent: string;
  created: Date;
  lastUsed: Date;
  continent: string;
  countryCode: string;
  city: string;
  lng: string;
  lat: string;
  operatingSystem: string;
  browser: string;


}
*/
