import {
    Component,
    computed,
    inject,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderStateService } from '@app/services/Header-state/header-state.service';
import { MatIcon } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { UserService } from '@app/services/user.service';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { clearLoginState } from '@app/store/actions';
import { Subscription } from 'rxjs';
import { selectUser } from '@app/store/selectors';
import { UserStateModel } from '@app/models/store/user';
import isEmpty from 'lodash/isEmpty';
import { SelectCompanyDialogComponent } from '@app/components/select-company/select-company-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataShareService } from '@app/services/data-share.service';
import { LocalStorageConstants } from '@app/constants/local-storage-constants';
import { ProfileComponent } from '../../components/profile/profile.component';
import { HttpClient } from '@angular/common/http';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { VersionComponent } from '../../components/version/version.component';
import { UserProfileService } from '@app/services/user-profile/user-profile.service';
import { SelectCompanyStateService } from '@app/services/select-company-state/select-company-state.service';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        ToolbarComponent,
        MatSidenavModule,
        MatListModule,
        CommonModule,
        RouterModule,
        MatIcon,
        MatDividerModule,
        ProfileComponent,
        TranslatePipe,
        VersionComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
    selectCompanyStateService = inject(SelectCompanyStateService);
    headerStateService = inject(HeaderStateService);
    userProfileService = inject(UserProfileService);
    dataShareService = inject(DataShareService);
    keycloakService = inject(KeycloakService);
    userService = inject(UserService);
    matDialog = inject(MatDialog);
    http = inject(HttpClient);
    store = inject(Store);

    userSubscription = signal<Subscription>(new Subscription());
    public userProfile = signal<KeycloakProfile | null>(null);
    user = signal<UserStateModel>(new UserStateModel());
    public selectedTenant = signal<string | null>(null);
    title = signal<string>('Workforce AI');
    public isLoggedIn = signal<boolean>(false);

    isSideNavOpen = computed(() =>
        this.headerStateService.select('isSideNavOpen')(),
    );

    labels = {
        copilotChat: signal<string>('app-header.copilotChat'),
        supportFeedback: signal<string>('app-header.supportFeedback'),
        privacyLegal: signal<string>('app-header.privacyLegal'),
        logout: signal<string>('app-header.logoutLabel'),
    };

    constructor() {
        this.userSubscription.set(
            this.store.select(selectUser).subscribe((user) => {
                this.userProfile.set(user);
            }),
        );

        this.selectedTenant.set(
            localStorage.getItem(LocalStorageConstants.SELECTED_TENANT),
        );
    }

    async ngOnInit(): Promise<void> {
        this.isLoggedIn.set(await this.userService.isLoggedIn());

        if (this.isLoggedIn()) {
            if (isEmpty(this.userProfile()?.id)) {
                this.userProfileService.loadAndSetUserProfile();
            }

            if (!this.selectedTenant()) {
                this.selectCompany();
            } else {
                // Broadcast the selected company to other components.
                const tenant = this.selectedTenant();
                this.selectCompanyStateService.set('company', tenant || '');
                if (tenant) {
                    this.dataShareService.broadcastSelectedCompanyObservable(
                        tenant,
                    );
                }
            }
        }
    }

    selectCompany(): void {
        const dialogRef = this.matDialog.open(SelectCompanyDialogComponent, {
            width: '250px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.checkSelectTenantResult(result);
        });
    }

    private checkSelectTenantResult(result: string | undefined) {
        if (!result) {
            if (!this.selectedTenant()) {
                // If we don't already have a tenant, then as the user again to select.
                this.selectCompany();
            }
        } else {
            this.updateSelectedCompany(result);
        }
    }

    private updateSelectedCompany(result: string) {
        this.dataShareService.broadcastSelectedCompanyObservable(result);
        this.selectedTenant.set(result);
        if (this.selectedTenant() !== null) {
            localStorage.setItem(
                LocalStorageConstants.SELECTED_TENANT,
                this.selectedTenant() as string,
            );
        }
    }

    async logout(): Promise<void> {
        this.store.dispatch(clearLoginState());
        return this.keycloakService
            .logout('http://localhost:4200/')
            .then(() => {})
            .catch((err) => {
                console.error('Err Logging out', err);
            });
    }

    ngOnDestroy(): void {
        this.userSubscription().unsubscribe();
    }
}
