import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const setUserInformation = createAction(
    types.GET_USER_INFO_SUCCESS,
    props<{ payload: any }>(),
);

export const getUserInformationFail = createAction(
    types.GET_USER_INFO_FAILED,
    props<{ payload: any }>(),
);

export const clearLoginState = createAction(types.CLEAR_LOGIN_INFO);
