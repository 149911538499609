// DO NOT EDIT THIS FILE
// This file is auto generated during the build & deployment process
// see npm run generate-git-version
export const gitVersion = {
    name: 'opscopilotui',
    version: '0.0.2',
    buildNumber: '1',
    commit: '3d59e20',
    commitLong: '3d59e206ee155c7dc05c2dc6bea7d1344d6548a4',
    branch: 'Detached: 3d59e206ee155c7dc05c2dc6bea7d1344d6548a4',
    buildTimestamp: '2024-11-07T06:49:16UTC',
};
