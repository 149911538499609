import { UserStateModel } from '@app/models/store/user';
import { StateModel } from '@app/models/store/state';
import { createSelector } from '@ngrx/store';

export const getUser = (state: StateModel): UserStateModel => state.user;

export const selectUser = createSelector(
    (state: StateModel) => state.user,
    (user: UserStateModel) => user,
);
