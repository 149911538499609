import { inject, Injectable, signal } from '@angular/core';
import { setUserInformation } from '@app/store/actions';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from '../user.service';
import { Store } from '@ngrx/store';
import { KeycloakUserInfo } from '@app/models/user';
import { selectUser } from '@app/store/selectors';
import { Subscription } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { UserStateModel } from '@app/models/store/user';

@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    keycloakService = inject(KeycloakService);
    userService = inject(UserService);
    store = inject(Store);

    userSubscription = signal<Subscription>(new Subscription());
    public userProfile = signal<UserStateModel>(new UserStateModel());

    async loadAndSetUserProfile(): Promise<void> {
        try {
            this.userSubscription.set(
                this.store.select(selectUser).subscribe((user) => {
                    this.userProfile.set(user);
                }),
            );
            let userProfile = this.userProfile();

            let loadProfile = this.userProfile()?.realm_access;

            if (isEmpty(this.userProfile()?.id)) {
                userProfile = await this.userService.loadUserProfile();
            }

            if (this.userProfile()?.realm_access?.roles?.length === 0) {
                const userInfo = (await this.keycloakService
                    .getKeycloakInstance()
                    .loadUserInfo()) as KeycloakUserInfo;

                loadProfile = userInfo.realm_access;
            }

            // Create the payload with the realm_access
            const payload = {
                ...userProfile,
                realm_access: loadProfile,
            };

            // Dispatch the action to update user information in the store
            this.store.dispatch(setUserInformation({ payload }));
        } catch (error) {
            console.error('Error loading user profile:', error);
            // Handle the error as necessary
        }
    }
}
