import { LocalStorageConstants } from '@app/constants/local-storage-constants';
import { GlobalVars, RuntimeEnvironment } from '@app/services/config.service';
import { DataShareService } from '@app/services/data-share.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { UserService } from '@app/services/user.service';
import { MatIconModule } from '@angular/material/icon';
import { KeycloakService } from 'keycloak-angular';
import { Component, inject, signal } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, NgStyle } from '@angular/common';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { HeaderStateService } from '@app/services/Header-state/header-state.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss',
    standalone: true,
    imports: [
        MatToolbarModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        NgStyle,
        TranslatePipe,
        DashboardComponent,
    ],
})
export class ToolbarComponent {
    headerStateService = inject(HeaderStateService);
    dataShareService = inject(DataShareService);
    keycloakService = inject(KeycloakService);
    userService = inject(UserService);
    matDialog = inject(MatDialog);

    public userProfile = signal<KeycloakProfile | null>(null);
    public selectedTenant = signal<string | null>(null);
    public isLoggedIn = signal<boolean>(false);

    labels = {
        title: signal<string>('app-toolbar.title.default'),
        logout: signal<string>('app-toolbar.logout'),
    };

    constructor() {
        this.selectedTenant.set(
            localStorage.getItem(LocalStorageConstants.SELECTED_TENANT),
        );
        this.labels.title.set(
            this.selectedTenant() === 'AK-CCD'
                ? 'app-toolbar.title.avatar'
                : 'app-toolbar.title.default',
        );
    }

    public getRuntimeEnv(): string {
        return GlobalVars.appConfig?.environment;
    }

    public isProdEnvironment(): boolean {
        return GlobalVars.appConfig?.environment === RuntimeEnvironment.prod;
    }

    onOpenSideNav(): void {
        this.headerStateService.set(
            'isSideNavOpen',
            !this.headerStateService.select('isSideNavOpen')(),
        );
    }
}
