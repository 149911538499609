import { Routes } from '@angular/router';
import { AuthGuard } from './security/keycloak.guard';

export const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        loadComponent: () =>
            import('./pages/dashboard/dashboard.component').then(
                (m) => m.DashboardComponent,
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
    {
        path: 'ama',
        loadComponent: () =>
            import('./components/chatbot/chatbot.component').then(
                (m) => m.ChatBotComponent,
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
    {
        path: 'manageFiles',
        loadComponent: () =>
            import('./components/upload-files/upload-files.component').then(
                (m) => m.UploadFilesComponent,
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
    {
        path: 'audit',
        loadComponent: () =>
            import('./components/audit-component/audit.component').then(
                (m) => m.AuditComponent,
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_ADMIN'],
        },
    },

    {
        path: 'privacy',
        loadComponent: () =>
            import('./pages/privacy-data/privacy-data.component').then(
                (m) => m.PrivacyDataComponent,
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_ADMIN'],
        },
    },
    {
        path: 'support-feedback',
        loadComponent: () =>
            import(
                './pages/support-and-feedback/support-and-feedback.component'
            ).then((m) => m.SupportAndFeedbackComponent),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
];
