export class UserStateModel {
    id?: string;
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    enabled?: boolean;
    emailVerified?: boolean;
    totp?: boolean;
    createdTimestamp?: number;
    realm_access?: {
        roles: string[];
    };

    constructor(props?: UserStateModel) {
        this.id = props?.id || '';
        this.username = props?.username || '';
        this.email = props?.email || '';
        this.firstName = props?.firstName || '';
        this.lastName = props?.lastName || '';
        this.enabled = props?.enabled || false;
        this.emailVerified = props?.emailVerified || false;
        this.totp = props?.totp || false;
        this.createdTimestamp = props?.createdTimestamp || 0;
        this.realm_access = props?.realm_access || { roles: [] };
    }
}

export class ProviderInfoModel {
    MICROSOFT = 'microsoft.com';
    GOOGLE = 'google.com';
    PHONE = 'phone';
}
