import { Injectable, inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

export const JWT_FIELD_TENNANTS = 'companies';

/**
 * Service related to managing the user. Provides methods to get the current company, and to check if user is logged or not
 *
 */
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private keycloakService = inject(KeycloakService);

    userProfile?: KeycloakProfile;

    async isLoggedIn(): Promise<boolean> {
        return await this.keycloakService.isLoggedIn();
    }

    async loadUserProfile(): Promise<KeycloakProfile> {
        this.userProfile = await this.keycloakService.loadUserProfile();
        return this.userProfile;
    }
}
