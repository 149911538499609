import {
    APP_INITIALIZER,
    importProvidersFrom,
    ApplicationConfig,
    isDevMode,
    provideZoneChangeDetection,
} from '@angular/core';

// This file is generated a
// If this is producing a compile error, then run `npm run generate-git-version` to generate the source file
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import {
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxFileDropModule } from 'ngx-file-drop';
import {
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { routes } from './app.routes';
import { provideRouter } from '@angular/router';
import { GlobalVars } from './services/config.service';
import { gitVersion } from 'environments/git-version';

import { ActionReducer, MetaReducer, provideStore } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { combinedReducers } from './store/reducers';
import {
    provideTranslateService,
    TranslateLoader,
} from '@codeandweb/ngx-translate';
import { TranslateHttpLoader } from '@codeandweb/http-loader';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

function localStorageSyncReducer(reducer: ActionReducer<any>): any {
    return localStorageSync({
        keys: ['user'],
        rehydrate: true,
        checkStorageAvailability: true,
    })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
const combinedReducer: any = combinedReducers;

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (
    http: HttpClient,
) => new TranslateHttpLoader(http, './i18n/', '.json');

function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () => {
        return keycloak.init({
            config: {
                url: 'https://keycloak.sitalab.aero/keycloak/auth',
                // url: 'https://ops-copilot-keycloak-v2.azurewebsites.net',
                realm: 'OperationsCoPilot',
                clientId: 'opscopilot2',
            },
            enableBearerInterceptor: true,
            initOptions: {
                enableLogging: true,
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/silent-check-sso.html',
            },
        });
    };
}

/**
 * This will load the config from the local file /config.json on startup.
 * There are multiple config files - local, dev, prod in the release bundle
 *
 * LOCAL DEV:
 * YOU, the developer, must copy the config.local.json to config.json when in local mode.
 *
 * K8S ENVIRONMENT:
 * The gitlab ci scripts / k8s deployment config will copy the appropriate dev/prod config file into the pod
 * when deploying to the k8s environment.
 */

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideTranslateService({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        provideRouter(routes),
        BrowserModule,
        importProvidersFrom(
            KeycloakAngularModule,
            FormsModule,
            MatIconModule,
            MatButtonModule,
            MatSelectModule,
            MatCardModule,
            ReactiveFormsModule,
            MatTableModule,
            MatSliderModule,
            MatTooltipModule,
            MatMenuModule,
            MatSnackBarModule,
            MatToolbarModule,
            MatFormFieldModule,
            MatInputModule,
            MatCheckboxModule,
            MatListModule,
            MatProgressBarModule,
            MatProgressSpinnerModule,
            NgxFileDropModule,
            NgxGoogleAnalyticsModule.forRoot('G-8CFE11SXK1'),
            NgxGoogleAnalyticsRouterModule,
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideStore(
            { router: routerReducer, ...combinedReducer },
            { metaReducers },
        ),
        provideRouterStore(),
        provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
        provideAnimationsAsync(),
    ],
};

function validateConfig() {
    if (!GlobalVars.appConfig?.environment) {
        // eslint-disable-next-line no-console
        console.log(
            `APP_INFO: ${gitVersion.name} env NOT_SET v${gitVersion.version} buildDate:${gitVersion.buildTimestamp} commit:${gitVersion.commit}`,
        );
        throw new Error('appConfig.environment is not set');
    } else {
        // Log build timestamp data.
        // If this is producing a compile error, then run `npm run generate-git-version` to generate the source file
        // eslint-disable-next-line no-console
        console.log(
            `APP_INFO: ${gitVersion.name} env ${GlobalVars.appConfig?.environment} v${gitVersion.version} buildDate:${gitVersion.buildTimestamp} commit:${gitVersion.commit}`,
        );
    }
}

fetch('/config.json')
    .then((response) => response.json())
    .then((config) => {
        GlobalVars.appConfig = config;
        validateConfig();
    })
    .catch((e) => {
        console.error('catch', e);
        window.alert(
            'Unable to load the /config.json file. There has been an error deploying the application. ',
        );
    });
