import { Injectable, inject, signal } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { UserStateModel } from '@app/models/store/user';
import { KeycloakUserInfo } from '@app/models/user';
import { selectUser } from '@app/store/selectors';
import { Store } from '@ngrx/store';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    store = inject(Store);

    protected override readonly router: Router;
    protected readonly keycloak: KeycloakService;

    private tokenParsed = signal<KeycloakTokenParsed | undefined>(undefined);
    private loadUserInfo = signal<KeycloakUserInfo>(new KeycloakUserInfo());
    userSubscription = signal<Subscription>(new Subscription());
    userProfile = signal<UserStateModel>(new UserStateModel());

    constructor() {
        const router = inject(Router);
        const keycloak = inject(KeycloakService);

        super(router, keycloak);
        this.router = router;
        this.keycloak = keycloak;

        this.userSubscription.set(
            this.store.select(selectUser).subscribe((user) => {
                this.userProfile.set(user);
            }),
        );
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Get the roles required from the route.
        const requiredRoles = route.data['roles'];

        const isAuditRoute = state.url.includes('/audit');

        let routeAllowed = true;

        if (isAuditRoute) {
            routeAllowed = false;
            if (this.userProfile()?.realm_access?.roles?.length === 0) {
                this.loadUserInfo.set(
                    (await this.keycloak
                        .getKeycloakInstance()
                        .loadUserInfo()) as KeycloakUserInfo,
                );
                routeAllowed = this.loadUserInfo()?.realm_access?.roles.some(
                    (role: string) => {
                        return role === 'ROLE_SITA_ADMIN';
                    },
                );
            } else {
                routeAllowed =
                    this.userProfile()?.realm_access?.roles.some(
                        (role: string) => {
                            return role === 'ROLE_SITA_ADMIN';
                        },
                    ) || true;
            }
        }

        // Allow the user to proceed if no additional roles are required to access the route.
        if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
            return true;
        }

        // const tokenParsed: Keycloak.KeycloakTokenParsed | undefined = this.keycloak.getKeycloakInstance().tokenParsed;
        // console.log(tokenParsed);

        // Allow the user to proceed if all the required roles are present.
        const allowed = requiredRoles.every((role) =>
            this.roles.includes(role),
        );

        return allowed && routeAllowed;
    }
}
