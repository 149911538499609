import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsDuration from 'dayjs/plugin/duration';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
dayjs.extend(relativeTime);
dayjs.extend(dayjsDuration);

type dateType = string | number | dayjs.Dayjs | Date | null | undefined;

@Injectable({
    providedIn: 'root',
})
export class TimeConversionService {
    addSubtractDate(
        type: 'add' | 'subtract',
        value: number,
        unit: dayjs.ManipulateType | undefined,
        date?: dateType,
        returnAsDate?: boolean,
    ): Date | dayjs.Dayjs {
        if (type === 'add') {
            if (returnAsDate) {
                return dayjs(date).add(value, unit).toDate();
            } else {
                return dayjs(date).add(value, unit);
            }
        } else {
            if (returnAsDate) {
                return dayjs(date).subtract(value, unit).toDate();
            } else {
                return dayjs(date).subtract(value, unit);
            }
        }
    }

    formatDate(format: string, date?: dateType): string {
        return dayjs(date).format(format);
    }

    getISOString(date?: dateType): string {
        return dayjs(date).toISOString();
    }

    getDuration(time: number, type: 'days' | 'hours'): number {
        switch (type) {
            case 'days':
                return dayjs.duration(time).asDays();
            case 'hours':
                return dayjs.duration(time).asHours();
        }
    }

    getDiffBetween(
        firstDate?: dateType,
        secondDate?: dateType,
        unit?: dayjs.ManipulateType | undefined,
    ): number {
        return dayjs(firstDate).diff(secondDate, unit);
    }

    compareDates(
        type: 'isSame' | 'isAfter' | 'isBefore',
        firstDate: dateType,
        secondDate: dateType,
    ): boolean {
        switch (type) {
            case 'isAfter':
                return dayjs(firstDate).isAfter(secondDate);
            case 'isSame':
                return dayjs(firstDate).isSame(secondDate);
            case 'isBefore':
                return dayjs(firstDate).isBefore(secondDate);
        }
    }

    timeFromNow(date: dateType, format?: string): string {
        return dayjs(date, format).fromNow();
    }

    calculateYearRange(startYear = 2024): string {
        const currentYear = dayjs().year();
        if (currentYear === startYear) {
            return `${startYear}`;
        }

        if (currentYear < startYear) {
            return `${startYear}`;
        }

        return `${startYear} - ${dayjs().year()}`;
    }

    isToday(date: dateType): boolean {
        return dayjs(date).startOf('day').isSame(dayjs().startOf('day'));
    }
}
