<mat-toolbar class="toolbar-theme" color="primary">
    <div class="menu-container">
        <div class="menu-icon-container">
            <mat-icon class="menu-icon-button" (click)="onOpenSideNav()"
                >menu</mat-icon
            >
        </div>

        <div class="title-container">
            <span id="title-toolbar">{{ labels.title() | translate }}</span>

            @if (!isProdEnvironment()) {
                <div class="project-environment">
                    <span
                        id="runtimeEnv"
                        matTooltip="You are NOT running on the production system"
                        >{{ getRuntimeEnv() | uppercase }}</span
                    >
                </div>
            }
        </div>
    </div>

    <div class="logo-container">
        <img id="sita-logo" src="/svg/logo.svg" alt="sita" />
    </div>
</mat-toolbar>
