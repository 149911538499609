<mat-card class="flex-container" id="select-company-tenant">
    <mat-card-header>
        <mat-card-title>
            {{ labels.selectCompanyAccountLabel() | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        @if (allowedTenants().length && allowedTenants().length > 0) {
            <div>
                <mat-list>
                    @for (t of allowedTenants(); track t) {
                        <mat-list-item>
                            <button
                                mat-button
                                type="button"
                                (click)="select(t)"
                            >
                                {{ t }}
                            </button>
                        </mat-list-item>
                    }
                </mat-list>
            </div>
        }
        @if (allowedTenants().length === 0) {
            <div>{{ labels.userNotLoadedMessage() | translate }}</div>
        }
    </mat-card-content>
    <mat-card-actions>
        <button mat-button type="button" (click)="cancel()">
            {{ labels.cancelButtonLabel() | translate }}
        </button>
    </mat-card-actions>
</mat-card>
