import { Injectable } from '@angular/core';
import { SignalsSimpleStoreService } from '../signals-simple-store/signals-simple-store.service';

interface TableState {
    isSideNavOpen: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class HeaderStateService extends SignalsSimpleStoreService<TableState> {
    constructor() {
        super();

        this.setState({
            isSideNavOpen: false,
        });
    }
}
