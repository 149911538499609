import { Component, inject, OnInit, signal } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { DialogModule } from '@angular/cdk/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectUser } from '@app/store/selectors';
import { UserProfileService } from '@app/services/user-profile/user-profile.service';
import { UserStateModel } from '@app/models/store/user';
import { SelectCompanyStateService } from '@app/services/select-company-state/select-company-state.service';

@Component({
    selector: 'app-select-company',
    templateUrl: './select-company-dialog.component.html',
    styleUrl: './select-company-dialog.component.scss',
    standalone: true,
    imports: [
        DialogModule,
        MatCardModule,
        MatListModule,
        MatButtonModule,
        TranslatePipe,
    ],
})
export class SelectCompanyDialogComponent implements OnInit {
    readonly dialogRef = inject(MatDialogRef<SelectCompanyDialogComponent>);
    selectCompanyStateService = inject(SelectCompanyStateService);
    protected readonly keycloak = inject(KeycloakService);
    userProfileService = inject(UserProfileService);
    store = inject(Store);

    private tokenParsed = signal<KeycloakTokenParsed | undefined>(undefined);
    public userProfile = signal<UserStateModel>(new UserStateModel());
    userSubscription = signal<Subscription>(new Subscription());
    JWT_FIELD_TENANTS = signal<string>('companies');
    allowedTenants = signal<string[]>([]);

    labels = {
        selectCompanyAccountLabel: signal<string>(
            'app-select-company.selectCompanyAccountLabel',
        ),
        userNotLoadedMessage: signal<string>(
            'app-select-company.userNotLoadedMessage',
        ),
        cancelButtonLabel: signal<string>(
            'app-select-company.cancelButtonLabel',
        ),
    };

    constructor() {
        this.userSubscription.set(
            this.store.select(selectUser).subscribe((user) => {
                this.userProfile.set(user);
            }),
        );
    }

    async ngOnInit(): Promise<void> {
        this.tokenParsed.set(this.keycloak.getKeycloakInstance().tokenParsed);

        if (this.userProfile()?.realm_access?.roles?.length === 0) {
            this.userProfileService.loadAndSetUserProfile();
        }

        if (!this.tokenParsed()) {
            await this.keycloak.getKeycloakInstance().loadUserInfo();

            this.tokenParsed.set(
                this.keycloak.getKeycloakInstance().tokenParsed,
            );
        }

        const tokenParsed = this.tokenParsed();

        if (tokenParsed) {
            this.allowedTenants.set(tokenParsed[this.JWT_FIELD_TENANTS()]);
        }
    }

    cancel(): void {
        this.dialogRef.close(undefined);
    }

    select(selectedCompany: string): void {
        this.selectCompanyStateService.set('company', selectedCompany);
        this.dialogRef.close(selectedCompany);
    }
}
