<mat-sidenav-container>
    <!-- Sidenav Component -->
    <mat-sidenav
        #sidenav
        mode="over"
        class="sidenav"
        [opened]="isSideNavOpen()"
        (closed)="headerStateService.set('isSideNavOpen', false)"
    >
        <div class="sidenav-header">
            <mat-icon
                class="menu-side-icon"
                (click)="headerStateService.set('isSideNavOpen', false)"
                >menu</mat-icon
            >
            <img
                id="side-nav-logo"
                src="/svg/logo-side-nav.svg"
                class="side-nav-logo"
                alt="sidenavLogo"
            />
        </div>

        <mat-divider class="sidenav-divider"></mat-divider>

        @if (selectedTenant()) {
            <div class="profile-sidenav-container">
                <app-profile
                    [selectedTenant]="selectedTenant()"
                    (selectCompany)="selectCompany()"
                    [userProfile]="userProfile()"
                ></app-profile>
            </div>
        }
        <mat-divider class="sidenav-divider"></mat-divider>

        <mat-nav-list>
            <a
                mat-list-item
                id="nav-copilotchat"
                [routerLink]="['/dashboard']"
                routerLinkActive="active"
                (click)="headerStateService.set('isSideNavOpen', false)"
                (keyup.enter)="headerStateService.set('isSideNavOpen', false)"
                tabindex="-1"
                >{{ labels.copilotChat() | translate }}</a
            >
            <a
                mat-list-item
                id="nav-supportFeedback"
                [routerLink]="['/support-feedback']"
                routerLinkActive="active"
                (click)="headerStateService.set('isSideNavOpen', false)"
                (keyup.enter)="headerStateService.set('isSideNavOpen', false)"
                tabindex="-1"
            >
                {{ labels.supportFeedback() | translate }}
            </a>
            <a
                mat-list-item
                id="nav-privacyLegal"
                [routerLink]="['/privacy']"
                routerLinkActive="active"
                (click)="headerStateService.set('isSideNavOpen', false)"
                (keyup.enter)="headerStateService.set('isSideNavOpen', false)"
                tabindex="-1"
            >
                {{ labels.privacyLegal() | translate }}
            </a>
        </mat-nav-list>
        <mat-divider class="sidenav-divider"></mat-divider>
        <mat-nav-list>
            <a
                id="logout-button"
                class="logout-button"
                mat-list-item
                (click)="logout()"
                (keyup.enter)="logout()"
                tabindex="-1"
            >
                {{ labels.logout() | translate }}
            </a>
        </mat-nav-list>
        <app-version class="version-container"></app-version>
    </mat-sidenav>

    <!-- Main Content including Toolbar -->
    <mat-sidenav-content>
        <!-- Main content of the page goes here -->

        <div class="main-content centered-content">
            <app-toolbar></app-toolbar>
            <ng-content></ng-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
