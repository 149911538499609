/* eslint-disable @typescript-eslint/consistent-generic-constructors */
import { GetFileStruture } from '@app/models/files.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataShareService {
    selectedFiles: GetFileStruture[] = [];

    // For components to track when the selected file(s) changes.
    // @ts-expect-error
    private _selectedFilesObservable: BehaviorSubject<GetFileStruture[]> =
        new BehaviorSubject([]);

    // For components to track when the selected tenant changes.
    // @ts-expect-error
    private _selectedCompanyObservable: BehaviorSubject<string> =
        new BehaviorSubject([]);

    // For components to track when the files change, and should be refreshed
    private _filesUpdated: BehaviorSubject<boolean> = new BehaviorSubject(
        false,
    );

    public broadcastFilesUpdated(): void {
        this._filesUpdated.next(true);
    }

    public broadcastSelectedFilesObservable(
        selectedFiles: GetFileStruture[],
    ): void {
        this._selectedFilesObservable.next(selectedFiles);
    }
    public broadcastSelectedCompanyObservable(newCompany: string): void {
        this._selectedCompanyObservable.next(newCompany);
    }

    get filesUpdatedObservable(): BehaviorSubject<boolean> {
        return this._filesUpdated;
    }

    get selectedFilesObservable(): BehaviorSubject<GetFileStruture[]> {
        return this._selectedFilesObservable;
    }
    get selectedCompanyObservable(): BehaviorSubject<string> {
        return this._selectedCompanyObservable;
    }
}
